// GET_USER
export const GET_ORDER = "GET_ORDER";

// GET_ORDER_DETAIL
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";

// ACCEPT_SELLER_REQUEST
// export const ACCEPT_ORDER = "ACCEPT_ORDER";
// EDIT_ORDER

export const UPDATE_ORDER = "UPDATE_ORDER";
