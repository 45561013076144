import React from "react";

const ToggleSwitch = (props) => {
  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          checked={props.value}
          onClick={props.onClick}
          className="checkbox"
          disabled={props.disabled}
        />
        <div class="slider"></div>
      </label>

      {/* <div className="checkbox-wrapper-5">
        <div className="check">
          <input
            type="checkbox"
            id="check-5"
            checked={props.value}
            onClick={props.onClick}
          />
          <label htmlFor="check-5" />
        </div>
      </div> */}
    </>
  );
};

export default ToggleSwitch;
