// GET_PRODUCT
export const GET_REAL_PRODUCT = "GET_REAL_PRODUCT";

export const GET_FAKE_PRODUCT = "GET_FAKE_PRODUCT";

// CREATE_PRODUCT
export const CREATE_PRODUCT = "CREATE_PRODUCT";

// UPDATE_PRODUCT

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";

// DELETE_PRODUCT
export const DELETE_PRODUCT = "DELETE_PRODUCT";

// PRODUCT_NEW_COLLECTION

export const PRODUCT_NEW_COLLECTION = "PRODUCT_NEW_COLLECTION";

// PRODUCT_OUT_OF_STOCK
export const PRODUCT_OUT_OF_STOCK = "PRODUCT_OUT_OF_STOCK";

// PRODUCT_DETAIL

export const PRODUCT_DETAIL = "PRODUCT_DETAIL";

// PRODUCT_REVIEW

export const PRODUCT_REVIEW = "PRODUCT_REVIEW";

// PRODUCT_REQUEST
export const PRODUCT_REQUEST = "PRODUCT_REQUEST";

// PRODUCT_REQUEST_ACTION
export const PRODUCT_REQUEST_ACTION = "PRODUCT_REQUEST_ACTION";
// UPDATE_PRODUCT_REQUEST
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";

// UPDATE_PRODUCT_REQUEST_ACTION
export const UPDATE_PRODUCT_REQUEST_ACTION = "UPDATE_PRODUCT_REQUEST_ACTION";
