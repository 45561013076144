import { useEffect, useState } from "react";
import Input from "../../extra/Input";
import Title from "../../extra/Title";
import SettingBox from "./SettingBox";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  updateSetting,
  handleToggleSwitch,
} from "../../store/setting/setting.action";
import { getWithdraw } from "../../store/withdraw/withdraw.action";
import Multiselect from "multiselect-react-dropdown";
import Button from "../../extra/Button";


const Setting = (props) => {
  const { setting } = useSelector((state) => state.setting);
  const { withdraw } = useSelector((state) => state.withdraw);
  
  const dispatch = useDispatch();
  // box 1
  const [settingId, setSettingId] = useState("");
  const [agoraKey, setAgoraKey] = useState("");
  const [agoraCertificate, setAgoraCertificate] = useState("");
  // box 2
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");

  const [isFakeData, setIsFakeData] = useState(false);

  // box 3
  // const [shippingCharges, setShippingCharges] = useState();
  const [withdrawCharges, setWithdrawCharges] = useState();
  const [cancelOrderCharges, setCancelOrderCharges] = useState();
  const [withdrawLimit, setWithdrawLimit] = useState();
  const [adminCommissionCharges, setAdminCommissionCharges] = useState();
  // box 4
  const [razorPayId, setRazorPayId] = useState("");
  const [razorSecretKey, setRazorSecretKey] = useState("");
  const [razorPaySwitch, setRazorPaySwitch] = useState(false);
  // box 5
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [stripeSwitch, setStripeSwitch] = useState(false);
  // box 6
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  // box 7
  const [isAddProductRequest, setIsAddProductRequest] = useState(false);
  const [isUpdateProductRequest, setIsUpdateProductRequest] = useState(false);

  // error

  const [error, setError] = useState({
    agoraKey: "",
    agoraCertificate: "",
    privacyPolicyLink: "",
    privacyPolicyText: "",
    // shippingCharges: "",
    withdrawCharges: "",
    withdrawLimit: "",
    cancelOrderCharges: "",
    adminCommissionCharges: "",
    razorPayId: "",
    razorSecretKey: "",
    stripePublishableKey: "",
    stripeSecretKey: "",
  });

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getWithdraw());
  }, [dispatch]);

  //onselect function of selecting multiple values
  function onSelect(selectedList, selectedItem) {
    setPaymentGateway(selectedList);
    setError({
      paymentGateway: "",
    });
  }

  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    setPaymentGateway(selectedList);
  }

  const paymentGateWayOption = withdraw.map((data) => {
    if (data && data?.name?.toLowerCase()) {
      return {
        name: data.name,
      };
    }
    return null;
  });

  useEffect(() => {
    const paymentGateway = setting?.paymentGateway?.map((data) => {
      return {
        name: data?.name?.toLowerCase(),
      };
    });
    setSettingId(setting?._id);
    // box 1
    setAgoraKey(setting?.agoraKey);
    setAgoraCertificate(setting?.agoraCertificate);
    // box 2
    setPrivacyPolicyLink(setting?.privacyPolicyLink);
    setPrivacyPolicyText(setting?.privacyPolicyText);
    setIsFakeData(setting?.isFakeData);
    // box 3
    // setShippingCharges(setting?.shippingCharges);
    setWithdrawCharges(setting?.withdrawCharges);
    setCancelOrderCharges(setting?.cancelOrderCharges);
    setWithdrawLimit(setting?.withdrawLimit);
    setAdminCommissionCharges(setting?.adminCommissionCharges);
    // box 4
    setRazorPayId(setting?.razorPayId);
    setRazorSecretKey(setting?.razorSecretKey);
    setRazorPaySwitch(setting?.razorPaySwitch);
    // box 5
    setStripePublishableKey(setting?.stripePublishableKey);
    setStripeSecretKey(setting?.stripeSecretKey);
    setStripeSwitch(setting?.stripeSwitch);
    //box 6
    setSelectedValue(paymentGateway);

    // box 7
    setIsAddProductRequest(setting?.isAddProductRequest);
    setIsUpdateProductRequest(setting?.isUpdateProductRequest);

    // setAppIsAppActive(setting?.isAppActive);
  }, [setting]);

  const handleSubmit = () => {
    if (
      !agoraKey ||
      !agoraCertificate ||
      !privacyPolicyLink ||
      !privacyPolicyText
    ) {
      let error = {};
      if (!agoraKey) error.agoraKey = "Agora Key Is Required ";
      if (!agoraCertificate)
        error.agoraCertificate = "AgoraCertificate Is Required ";
      if (!privacyPolicyLink)
        error.privacyPolicyLink = "Privacy Policy Link Is Required ";
      if (!privacyPolicyText)
        error.privacyPolicyText = "Privacy Policy Text Is Required ";

      return setError({ ...error });
    } else {
      
      let settingData = {
        agoraKey,
        agoraCertificate,
        privacyPolicyLink,
        privacyPolicyText,
      };

      props.updateSetting(settingData, setting?._id);
    }
  };

  const handleClick = (type) => {
    //Handle Update Switch Value
    
    props.handleToggleSwitch(setting._id, type, setting);
  };

  return (
    <>
      <div className="mainSettingBar">
        <div className="settingBar ">
          <div className="settingHeader primeHeader">
            <div className="row align-items-center">
              <div className="col-6"></div>
              <div className="col-6 text-end">
                {/* <Title name={`Setting`} /> */}
              </div>
            </div>
          </div>
          <div className="settingMain">
            <div className="row">
              {/*-------------- Box 1 --------------*/}
              <SettingBox
                // submit={(e) => handleSubmit(e)}
                title={`Zegocloud Setting`}
              >
                <Input
                  type={`text`}
                  label={`Zegocloud App Id`}
                  value={agoraKey}
                  newClass={`col-12`}
                  placeholder={`Enter You Agora Key....`}
                  errorMessage={error.agoraKey && error.agoraKey}
                  onChange={(e) => {
                    setAgoraKey(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        agoraKey: `Agora Key Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        agoraKey: "",
                      });
                    }
                  }}
                />
                <Input
                  type={`text`}
                  label={`Zegocloud App Sign In`}
                  value={agoraCertificate}
                  newClass={`col-12`}
                  errorMessage={
                    error.agoraCertificate && error.agoraCertificate
                  }
                  onChange={(e) => {
                    setAgoraCertificate(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        agoraCertificate: `AgoraCertificate Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        agoraCertificate: "",
                      });
                    }
                  }}
                />
              </SettingBox>

              {/*-------------- Box 2 --------------*/}
              <SettingBox
                // submit={(e) => handleSubmit(e)}
                title={`App Setting`}
              >
                <Input
                  type={`text`}
                  label={`Privacy Policy Link`}
                  value={privacyPolicyLink}
                  newClass={`col-12`}
                  placeholder={`Enter You Privacy Policy Link....`}
                  errorMessage={
                    error.privacyPolicyLink && error.privacyPolicyLink
                  }
                  onChange={(e) => {
                    setPrivacyPolicyLink(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        privacyPolicyLink: `Privacy Policy Link Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        privacyPolicyLink: "",
                      });
                    }
                  }}
                />

                <Input
                  type={`text`}
                  label={`Privacy Policy Text`}
                  value={privacyPolicyText}
                  newClass={`col-12`}
                  placeholder={`Enter You Privacy Policy Text....`}
                  errorMessage={
                    error.privacyPolicyText && error.privacyPolicyText
                  }
                  onChange={(e) => {
                    setPrivacyPolicyText(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        privacyPolicyText: `Privacy Policy Text Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        privacyPolicyText: "",
                      });
                    }
                  }}
                />
              </SettingBox>

              {/* Box 7 */}
              <SettingBox
                title={`Add Product Request`}
                toggleSwitch={{
                  switchValue: isAddProductRequest,
                  handleClick: () => {
                    handleClick("productRequest");
                  },
                }}
              ></SettingBox>
              <SettingBox
                title={`Update Product Request`}
                toggleSwitch={{
                  switchValue: isUpdateProductRequest,
                  handleClick: () => {
                    handleClick("updateProductRequest");
                  },
                }}
              ></SettingBox>
              <SettingBox
                title={`Fake Data `}
                toggleSwitch={{
                  switchName: " ",
                  switchValue: isFakeData,
                  handleClick: () => {
                    handleClick("isFakeData");
                  },
                }}
              ></SettingBox>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end">
                <Button
                  newClass={`whiteFont`}
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ width: "90px", borderRadius: "6px" }}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getSetting,
  updateSetting,
  handleToggleSwitch,
  getWithdraw,
})(Setting);
